import React from 'react';
import {Grid} from '@mui/material';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

const DropAnimation = css`
  -webkit-animation: bounce-top .9s both;
  animation: 900ms ease 0ms 1 normal both running bounce-top;
  
  @keyframes bounce-top {
    0% {
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    65% {
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    82% {
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    25%, 55%, 75%, 87% {
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }

`;

const Container = styled.div`
  ${DropAnimation};
  width: 100%;
  max-width: 900px;
  aspect-ratio: 1/1.15;
  margin: 5rem auto 0 auto;
`;

const MainImg = styled.img`
  width: 100%;
  aspect-ratio: 1/0.66;
  background-size: cover;
`;

const LinkImg = styled.img`
  width: 100%;
  aspect-ratio: 1/1.5;
  background-size: cover;
`;

function Home() {
  return (
    <Grid container spacing={0}>
      <Grid item xs={0} md={3} />
      <Grid item xs={12} md={6}>
        <Container>
          <MainImg src="./static/mainImage.png" />
          <Grid sx={{mt: '1rem'}} container item spacing={0}>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={3}>
              <Link to="/illustration">
                <LinkImg src="./static/illustrationLink.png"/>
              </Link>
            </Grid>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={3}>
              <Link to="/about">
                <LinkImg src="./static/aboutLink.png"/>
              </Link>
            </Grid>
            <Grid item xs={0} md={2} />
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={0} md={3} />
    </Grid>
  );
}

export default Home;
