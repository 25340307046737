import React from 'react';
import './App.css';
import {Reset} from 'styled-reset';
import {Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Illustration from './pages/Illustration';
import About from './pages/About';


function App() {
  return (
    <div>
      <Reset />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/illustration" element={<Illustration />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
