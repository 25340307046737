import React from 'react';
import {Grid} from '@mui/material';
import styled from 'styled-components';

const UnderConstructionImg = styled.img`
  background-size: cover;
`;


function Illustration() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{minHeight: '100vh'}}
    >
      <Grid item xs={3}>
        <UnderConstructionImg src="./static/underConstruction.jpg"/>
      </Grid>
    </Grid>
  );
}

export default Illustration;
